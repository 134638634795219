import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card, Col, Row } from "react-bootstrap"
import Img from "gatsby-image"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"

class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { homeDescription } = data
    const artists = data.artists?.edges

    return (
      <Layout location={this.props.location}>
        <SEO title="Page d'accueil" />
        <Row className="justify-content-center">
          <Col lg={8} md={10} className="mb-4">
            <MDXRenderer>{homeDescription.body}</MDXRenderer>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={9} sm={10}>
            <Row
              noGutters
              className="align-items-center justify-content-center"
            >
              {artists?.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug
                const { cover, genre, color } = node.frontmatter
                return (
                  <Col md={3} sm={8} className="p-2" key={node.fields.slug}>
                    <ArtistCard>
                      <Img
                        fixed={cover.childImageSharp.fixed}
                        className="mx-auto card-img"
                      />
                      <GenreOverlay className="text-white">
                        {genre && (
                          <Card.Text style={{ borderColor: color }}>
                            {genre}
                          </Card.Text>
                        )}
                      </GenreOverlay>
                      <Card.Body className="p-2">
                        <Card.Title className="mb-0">
                          <Link
                            to={node.fields.slug}
                            className="stretched-link"
                          >
                            {title}
                          </Link>
                        </Card.Title>
                      </Card.Body>
                    </ArtistCard>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </Layout>
    )
  }
}

const ArtistCard = styled(Card)`
  & .card-img {
    aspect-ratio: 1;
  }
`

const GenreOverlay = styled(Card.ImgOverlay)`
  opacity: 0;
  transition: opacity 0.1s ease;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${ArtistCard}:hover & {
    opacity: 1;
  }

  p {
    border-bottom: 3px solid;
  }
`

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    homeDescription: mdx(frontmatter: { contentType: { eq: "home" } }) {
      body
    }
    artists: allMdx(
      filter: { frontmatter: { contentType: { eq: "artist" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            cover {
              childImageSharp {
                fixed(
                  width: 188
                  height: 188
                  fit: CONTAIN
                  background: "white"
                ) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
            genre
            color
          }
        }
      }
    }
  }
`
